import { useSelector } from 'react-redux';
import { shouldUseNewOfferExpiration } from '../../../growthbook';
import { RemoteConfigUtils } from '../../../utils/RemoteConfigUtils';

export const useNotificationTypeMap = () => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);

  const officeAddressState = user?.address?.[0]?.state;

  const offerExpirationStates = RemoteConfigUtils.getOfferExpirationStates();
  const shouldShowNewOfferExpiration =
    offerExpirationStates.includes(officeAddressState) && shouldUseNewOfferExpiration();

  const unlimitedApplicationEnabledStates =
    RemoteConfigUtils.getUnlimitedApplicationEnabledStates();
  const shouldShowNewCandidateApplied =
    unlimitedApplicationEnabledStates.includes(officeAddressState);

  const notificationTypeMap = {
    ...(shouldShowNewCandidateApplied && {
      OFFICE_EVENT_OFFER_ACTION_REQUIRED: {
        group: 'Offer Activity',
        title: 'Candidate Applied',
        description:
          'These are notifications to let you know how many shifts have outstanding offers. *Sent at 9:30 AM, 11:30 AM, and 6:30 PM*',
        isPush: true,
        isText: true,
        isEmail: true,
        isAlertAvailable: true,
      },
    }),
    ...(!shouldShowNewCandidateApplied && {
      OFFICE_EVENT_OFFER_RECEIVED: {
        group: 'Offer Activity',
        title: 'Candidate Applied',
        description:
          'These are notifications to let you know when a professional applies for one of your shifts at the listed rate.',
        isPush: true,
        isText: true,
        isEmail: true,
        isAlertAvailable: true,
      },
      OFFICE_EVENT_COUNTER_OFFER_RECEIVED: {
        group: 'Offer Activity',
        title: 'Candidate Applied with Counter-Offer',
        description:
          'These are notifications to let you know when a professional applies for one of your shifts and is requesting a higher rate than what was listed.',
        isPush: true,
        isText: false,
        isEmail: true,
        isAlertAvailable: true,
      },
    }),
    OFFICE_EVENT_SINGLE_SHIFT_JOB_POSTED: {
      group: 'Confirmations',
      title: 'Shift Posting Confirmation (Single-Day Shift)',
      description:
        'These are notifications to let you know that your single shift request has been listed successfully.',
      isPush: false,
      isText: false,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_MULTI_DAY_JOB_POSTED: {
      group: 'Confirmations',
      title: 'Shifts Posting Confirmation (Multi-Day Booking)',
      description:
        'These are notifications to let you know that your multi-day booking request has been listed successfully.',
      isPush: false,
      isText: false,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_15_MINUTES: {
      group: 'Offer Activity',
      title: 'Offer Expiration Warning (15 minutes)',
      description:
        'These are notifications to let you know when a counter offer is close to expiration.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
      hidden: shouldShowNewOfferExpiration,
    },
    OFFICE_EVENT_JOB_CANCELLED_BY_PROFESSIONAL: {
      group: 'Shift Notifications',
      title: 'Shift Cancelled by Professional',
      description:
        'These are notifications to let you know when a confirmed professional has cancelled a shift, and whether it was re-posted with auto-confirm.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: true,
    },
    OFFICE_EVENT_CANCELLED_JOB_REFILLED: {
      group: 'Shift Notifications',
      title: 'Candidate Auto-Confirmed for a Shift',
      description:
        'These are notifications to let you know when a candidate was auto-confirmed for your shift, or when a re-posted job has been re-filled by a professional.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: true,
    },
    OFFICE_EVENT_MESSAGE_RECEIVED: {
      group: 'Inbox',
      title: 'New Chat received from Professional',
      description:
        'These are notifications to let you know when a professional directly messages you within the app.',
      isPush: true,
      isText: true,
      isEmail: false,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_1_HOUR: {
      group: 'Offer Activity',
      title: 'Offer Expiration Warning (60 minutes)',
      description:
        'These are notifications to let you know when a counter offer is 1 hour away from expiring.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
      hidden: shouldShowNewOfferExpiration,
    },
    OFFICE_EVENT_SHIFT_ENDED: {
      group: 'End-of-Day & Adjustments',
      title: 'Shift Ended',
      description:
        'These are notifications that remind you to submit an adjustment (if needed) and review the professional.',
      isPush: true,
      isText: false,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_SHIFT_ADJUSTED_BY_PROFESSIONAL: {
      group: 'End-of-Day & Adjustments',
      title: 'Professional Submits a Shift Adjustment',
      description:
        'These are notifications to let you know that a professional has submitted an adjustment request.',
      isPush: true,
      isText: false,
      isEmail: true,
      isAlertAvailable: true,
    },
    OFFICE_SETTING_ALLOW_MARKETING_OUTREACH: {
      group: 'Promotions & Account Support',
      title: 'GoTu News & Updates',
      description:
        'These are notifications that send you latest updates from GoTu, including product updates, events, and more.',
      isPush: false,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
    },
    OFFICE_EVENT_OFFER_EXPIRATION: {
      group: 'Offer Activity',
      title: 'Offer Expiration Warning',
      description:
        'These are notifications to let you know how many outstanding offers will expire today.',
      isPush: true,
      isText: true,
      isEmail: true,
      isAlertAvailable: false,
      hidden: !shouldShowNewOfferExpiration,
    },
  };

  const shouldShowWarning = (type, settings) =>
    notificationTypeMap[type]?.isAlertAvailable &&
    (notificationTypeMap[type]?.isPush ? !settings?.push : true) &&
    (notificationTypeMap[type]?.isEmail ? !settings?.email : true) &&
    (notificationTypeMap[type]?.isText ? !settings?.text : true);

  const groupAndSortNotifications = (notifications) => {
    // Add missing fields to notifications
    const mappedNotifications = notifications
      .filter((notification) => !notificationTypeMap[notification.event]?.hidden)
      .map(({ event, ...settings }) => ({
        event,
        ...notificationTypeMap[event],
        ...settings,
      }));

    // Reorder mappedNotifications based on notificationEventOrder
    mappedNotifications.sort((a, b) => {
      const orderA = notificationEventOrder[a.event] || Infinity;
      const orderB = notificationEventOrder[b.event] || Infinity;
      return orderA - orderB;
    });

    // Create a object with the group as key and the notifications as value
    const groupedNotifications = mappedNotifications.reduce((acc, notification) => {
      const { group } = notification;
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(notification);
      return acc;
    }, {});

    // Sort each group based on the group order
    const sortedNotifications = Object.fromEntries(
      notificationsGroups.map(({ title, description }) => {
        const groupItems = groupedNotifications[title] || [];
        return [title, { description, items: groupItems }];
      }),
    );

    // Remove keys with empty arrays
    Object.keys(sortedNotifications).forEach((key) => {
      if (sortedNotifications[key].items.length === 0) {
        delete sortedNotifications[key];
      }
    });

    return sortedNotifications;
  };

  return {
    shouldShowWarning,
    notificationTypeMap,
    groupAndSortNotifications,
  };
};

const notificationEventOrder = {
  OFFICE_EVENT_OFFER_ACTION_REQUIRED: 1,
  OFFICE_EVENT_OFFER_RECEIVED: 2,
  OFFICE_EVENT_COUNTER_OFFER_RECEIVED: 3,
  OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_1_HOUR: 4,
  OFFICE_EVENT_COUNTER_OFFER_EXPIRATION_WARNING_15_MINUTES: 5,
  OFFICE_EVENT_OFFER_EXPIRATION: 6,
  OFFICE_EVENT_SINGLE_SHIFT_JOB_POSTED: 7,
  OFFICE_EVENT_MULTI_DAY_JOB_POSTED: 8,
  OFFICE_EVENT_JOB_CANCELLED_BY_PROFESSIONAL: 9,
  OFFICE_EVENT_CANCELLED_JOB_REFILLED: 10,
  OFFICE_EVENT_MESSAGE_RECEIVED: 11,
  OFFICE_EVENT_SHIFT_ENDED: 12,
  OFFICE_EVENT_SHIFT_ADJUSTED_BY_PROFESSIONAL: 13,
  OFFICE_SETTING_ALLOW_MARKETING_OUTREACH: 14,
};

const notificationsGroups = [
  { title: 'Offer Activity', description: 'Candidate Applied, Offer Expiration.' },
  { title: 'Confirmations', description: 'Shift Posting Confirmations.' },
  {
    title: 'Shift Notifications',
    description:
      'Shift Cancelled by Professional, Candidate Auto-Confirmed for a Shift.',
  },
  {
    title: 'End-of-Day & Adjustments',
    description: 'Shift Ended, Professional Submits a Shift Adjustment.',
  },
  { title: 'Inbox', description: 'New Chat Received from Professional.' },
  { title: 'Promotions & Account Support', description: 'GoTu News & Updates.' },
];
