import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultGeneralSpecialty } from '../../../constants';
import useLoading from '../../../hooks/useLoading';
import {
  clearJobCandidates,
  FETCH_JOB_CANDIDATES_ACTION,
  fetchJobCandidates,
  showJobCandidatesForPage,
} from '../../../actions';
import { candidateSortOptions, defaultFilters } from '../utils';

export const useJobCandidates = () => {
  const dispatch = useDispatch();

  const { job, visibleCandidates, candidatesFilters, bestPickCandidates } = useSelector(
    (state) => state.job,
  );

  const { page, totalPages } = candidatesFilters || {};

  const { isLoading } = useLoading([FETCH_JOB_CANDIDATES_ACTION]);

  const fetchCandidates = useCallback(
    async (jobId, specialty) => {
      const isGeneralDentistryJob = specialty === DefaultGeneralSpecialty;
      await dispatch(clearJobCandidates());
      dispatch(
        fetchJobCandidates({
          jobId,
          filters: {
            ...defaultFilters,
            sort: !isGeneralDentistryJob
              ? candidateSortOptions.skillsMatch
              : candidateSortOptions.localFavorites,
          },
          resetBestPick: true,
        }),
      );
    },
    [dispatch],
  );

  const reloadCandidates = useCallback(
    (jobId) => {
      dispatch(fetchJobCandidates({ jobId, filters: candidatesFilters, resetBestPick: false }));
    },
    [dispatch, candidatesFilters],
  );

  const onFilterChange = useCallback(
    async (filterValues) => {
      if (job) {
        await dispatch(clearJobCandidates());
        const filters = { ...candidatesFilters, ...filterValues, page: 1 };
        dispatch(fetchJobCandidates({ jobId: job.id, filters, resetBestPick: true }));
      }
    },
    [dispatch, job, candidatesFilters],
  );

  const loadPrevPageCandidates = useCallback(() => {
    dispatch(showJobCandidatesForPage(page - 1));
  }, [dispatch, page]);

  const loadNextPageCandidates = useCallback(() => {
    dispatch(showJobCandidatesForPage(page + 1));
  }, [dispatch, page]);

  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  return {
    fetchCandidates,
    reloadCandidates,
    onFilterChange,
    loadPrevPageCandidates,
    loadNextPageCandidates,
    candidates: visibleCandidates,
    filters: candidatesFilters,
    bestCandidates: bestPickCandidates,
    isLoading,
    isFirstPage,
    isLastPage,
  };
};
